<template>
  <main>
    <div class="container -block">
      <header class="view-checkout-cart__header">
        <actions-button
          :appearance="$pepper.Appearance.SUBTLE"
          :href="menuHref"
          icon-pre="arrow-left"
          :size="$pepper.Size.S"
        >{{ $t('resto.checkout_cart_back') }}</actions-button>

        <h1 class="view-checkout-cart__title">{{ $t('resto.checkout_cart_title', { service }) }}</h1>

        <notice-banner
          class="view-checkout-cart__banner"
          :description="$t(`resto.${shopOpened.description}`)"
          :intent="$pepper.Intent.DANGER"
          :title="$t(`resto.${shopOpened.title}`)"
          v-if="!shopOpened.value"
        />
      </header>

      <div
        class="view-checkout-cart__primary"
        v-if="!isEmpty">
        <div class="view-checkout-cart__badge">
          <checkout-cart-badge />
        </div>

        <!-- Cart -->
        <section class="view-checkout-cart__cart checkout-cart">
          <cart
            editable
            :shopable="isMultiShop"
          />
        </section>

        <div 
          class="view-catalog-cart__danger"
          v-if="hasItemsFromClosedShop">
          <notice-banner
            :intent="$pepper.Intent.DANGER"
            :title="$t('resto.cart_some_shops_closed_title')"
          />
        </div>
      </div>

      <!-- Empty -->
      <div
        class="view-checkout-cart__body"
        v-if="isEmpty">
        <section class="view-checkout-cart__cart checkout-cart">
          <data-empty
            :description="$t('resto.empty_description')"
            :primary-action="$t('resto.empty_primary_action')"
            :title="$t('resto.empty_title')"
            @click="onEmptyClick"
          />
        </section>
      </div>

      <footer
        class="view-checkout-cart__footer"
        v-if="!isEmpty">
        <!-- Actions -->
        <section class="view-checkout-cart__actions">
          <actions-button
            :appearance="$pepper.Appearance.PRIMARY"
            :disabled="!isEnabled"
            :href="{ name: 'sayl-front-checkout.review' }"
            icon-post="arrow-right"
          >{{ $t('resto.checkout_cart_go_to_checkout') }}</actions-button>
        </section>
      </footer>
    </div>
  </main>
</template>

<script>
import { mapState } from 'vuex'
import Cart from '@/modules/catalog/components/cart/cart'
import CheckoutCartBadge from '@/modules/checkout/components/cart/badge'

import MixinMultishop from '@/helpers/multishop'
import MixinOrderable from '@/helpers/orderable'

export default {
  name: 'CheckoutCart',

  components: {
    Cart,
    CheckoutCartBadge,
  },

  inject: [
    '$localStorage',
    '$catalog'
  ],

  mixins: [ 
    MixinMultishop,
    MixinOrderable
  ],

  computed: {
    ...mapState({
      cart: state => state['sayl-front.cart'].cart,
      ser: state => state['sayl-front.service'].service,
    }),

    isEmpty() {
      return this.cart && this.cart.items.length === 0;
    },

    menuHref() {
      if(this.ser && this.ser.name) {
        return {
          name: this.isMultiShop ? 'sayl-front-catalog.shops' : 'sayl-front-catalog.catalog',
          params: {
            embed: this.$route.params.embed,
            service: this.ser.name,
          }
        }
      }
      return {
        name: 'sayl-front-catalog.services',
        params: {
          embed: this.$route.params.embed,
        }
      }
    },

    service() {
      let s = this.$basil.get(this.cart, 'service', 'eatin');
      return this.$t('resto.service_' + s);
    },
  },

  methods: {
    onEmptyClick(event, action) {
      this.$router.push(this.menuHref).catch(() => {})
    },
  },

  mounted() {
    this.$bus.$emit('injekt.sp.cart')

    if(this.ser?.name && this.ser?.menuId) {
      this.$catalog.init({
        id: this.ser.menuId,
        service: this.ser.name,
        locale: this.$i18n.locale
      })
    }

    if(this.ser == null || this.ser.menuId == null) {
      this.$router.push(this.menuHref)
    }
  }
}
</script>
